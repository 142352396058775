import React from 'react';
import styled from 'styled-components';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { BaseP, HeaderMedium } from 'components/typography';
import ArrowButton from 'components/Button/ArrowButton';
import { mainContainerWidth } from 'common/styledVars';
import { RelativeWrapper, WidthWrapper } from 'components/wrappers';
import VideoBG from './video_background.jpg';
import ShipkooPoster from './video-poster.jpg';
import Icon1 from 'images/icon1_how_it_works.png';
import Icon2 from 'images/icon2_how_it_works.png';
import Icon3 from 'images/icon3_how_it_works.png';
import ShipkooVideo from 'images/shipkoo-video-thumbnail.png';
import AllInOneBg from 'images/all-in-one-background.png';
import InventoryManagement from 'images/inventory-management.png';
import OrderManagement from 'images/order-management.png';
import ShippingManagement from 'images/shipping-management.png';
import WhiteLine from 'images/white-line.png';
const {useState} = React;


const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="video-container" id="shipkoo_video">
    <a target="_blank" href="https://www.youtube.com/watch?v=c60Nh8Rq_Q8&feature=youtu.be">
      <img src={ShipkooVideo} alt ="How We Can Help You" />
    </a>
  </div>
)


const CTAHeader = styled.h4`
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 45px;

  @media only screen and (min-width: 1024px) {
    font-size: 36px;
  }
`;

const Container = styled.div`
  margin: 120px auto;
  max-width: ${mainContainerWidth.desktop + 30}px;
  width: 100%;
  display: flex;
  padding: 0 15px;
`;

const ImgWrapper = styled.div`
  width: 55%;
  background-image: url('/icons/infinity-3.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const TextWrapper = styled.div`
  flex: 1;
  margin-right: 115px;

  .reach-us-header {
    margin-bottom: 45px;
  }

  .reach-us-header a {
    font-size: 14px;
    font-weight: 300;
    color: #00AF85;
  }

  @media only screen and (min-width: 1024px) {
    .reach-us-header a {
      font-size: 16px;
    }
  }

  ${HeaderMedium} {
    margin-bottom: 45px;
  }
`;

const MainWrapper = styled(RelativeWrapper)`

  .column {
    float: left;
    width: 51%;
    margin-right:2%;
    padding: 10px;
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .column.last {
    margin-right:0 !important;
    width:47%;
  }

  #all-in-one {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    padding-top: 40px;
    position: relative;
    z-index:99;
  }

  #all-in-one-line:before {
    display: inline-block;
    content: "";
    border-top: 2.5px solid #fff;
    width: calc(50% - 530px);
    position:absolute;
    bottom:107px;
    left:0;
  }

  #all-in-one-line:after {
    display: inline-block;
    content: "";
    border-top: 2.5px solid #fff;
    width: calc(50% - 527px);
    position:absolute;
    bottom: 107px;
    right:0;
  }

  .aio-btn-container:after {
    display: inline-block;
    content: "";
    border-top: 2.5px solid #fff;
    position: absolute;
    bottom: 28px;
    right: -86px;
    width: 184px;
  }

  .aio-btn-container.active:after {
    display: inline-block;
    content: "";
    border-top: 2.5px solid #fff;
    position: absolute;
    bottom: 28px;
    right: -24px;
    width: 121px;

  }

  .aio-btn-container:hover {
    cursor:pointer;
  }

  #all-in-one .hiw-title {
    font-size: 36px;
    margin-bottom: 35px;
    line-height: 45px;
    font-family: CircularStd;
    margin-left: 60px;
    font-weight: 600;
  }
  #all-in-one .hiw-list {
    font-size:20px;
    font-weight:500;
    margin-bottom:0px;
  }
  #all-in-one .text-desc{
    font-size:15px;
    line-height:30px;
    margin-bottom:30px;
  }
  #all-in-one .text-desc-container {
    width: 89%;
    display: inline-block;
    float: left;
  }

  #all-in-one .img-container {
    display: inline-block;
    width: 9%;
    margin-right:2%;
    float: left;
  }

  #all-in-one .img-container img {
    max-width: 45px;
    width:100%;
  }

  #all-in-one .list-column{
      padding-bottom:20px;
  }

  #shipkoo_video {
    -webkit-box-shadow: 8px 18px 30px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 8px 18px 30px 0px rgba(0,0,0,0.2);
    box-shadow: 8px 18px 30px 0px rgba(0,0,0,0.2);
    width:100%;
    margin-top:10px;
    border:6px solid #888989;
    transition: transform .5s ease;
  }

  #shipkoo_video:hover {
    transform: scale(1.02);
  }

  .video-container {
    overflow: hidden;
    position: relative;
    width:100%;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #section-background {
    position: absolute;
    width: 100%;
  }

  #section-background img {
    /*max-height: 650px;*/
    width: 100%;
    min-height: 620px;
  }

  .section-background-container {
    position: relative;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }

  .clear {
    clear: both;
  }

  #all-in-one {
    padding-bottom:70px;

  }

  #all-in-one-section .background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #all-in-one-section .aio-image {
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  #all-in-one-section .aio-image.order-management {
    bottom: 64px;
  }

  #all-in-one-section .aio-image.shipping-management {
    bottom: 77px;
  }

  .aio-subheader {
    font-size: 30px;
    color: #fff;
    margin-bottom: 30px;
    font-weight: 500;
  }

  .aio-desc {
    color: #fff;
    line-height: 1.5;
    padding-right: 32px;
  }

  .aio-btn-container button {
    min-width: 139px;
    max-width: 139px;
    background: transparent;
    border: 2px solid #FFF;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 600;
    color: #fff;
    margin-right: 8px;
    min-height:54px;
  }

  .aio-btn-container a {
    padding-bottom: 31px;
    padding-top: 10px;
  }

  .aio-btn-container button:hover {
    cursor:pointer;
    color:#00af85;
    background:#fff;
  }

  .aio-btn-container button.active{
    color:#00AF85;
    background:#fff;
    pointer-events:none;
  }

  .aio-btn-container button:hover {
    cursor:pointer;
  }

  .aio-btn-container {
    margin-top:35px;
    position:relative;
  }

  .aio-contents {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    height:0;
  }

  .aio-contents.active {
    visibility: visible;
    opacity: 1;
    height:auto;
  }

  @media only screen and (min-width:1450px) {
     .aio-btn-container button {
      margin-right: 9px;
     }
  }

  @media only screen and (max-width:1080px) {
    .aio-btn-container {
      padding-left:0;
    }

    .aio-btn-container:after {
      display:none !important;
    }

    #all-in-one-section .aio-image {
      display: none !important;
    }

    .aio-btn-container button {
      margin-bottom:10px;
    }

    .aio-subheader {
      margin-bottom:10px;
    }

    #all-in-one .hiw-title {
      font-size: 28px !important;
      margin-left:0 !important;
      text-align:center;
      margin-top: 20px;
    }
    #all-in-one {
      padding-top: 20px;
      padding-bottom: 50px;
    }
  }

  @media only screen and (max-width:768px) {
    .column {
      width:100% !important;
      margin-right:0 !important;
      float:none;
    }

    #all-in-one .hiw-title {
      font-size: 28px !important;
      margin-left:0 !important;
      text-align:center;
      margin-top: 20px;
    }

    #all-in-one {
      padding-top: 15px;
      padding-bottom: 50px;
      padding-left: 10px;
      padding-right: 10px;
    }

    #section-background {
      display:none;
    }

    #all-in-one-section {
      margin-top: 0 !important;
      margin-bottom: -60px !important;
    }

    .aio-btn-container {
      padding-left:0;
    }

    #all-in-one-section .aio-image {
      display: none !important;
    }

    .aio-btn-container button {
      margin-bottom:10px;
      padding-left: 20px;
      padding-right: 20px;
      min-width: 0;
    }

    .aio-subheader {
      margin-bottom:10px;
    }

  }
  @media only screen and (max-width:425px) {
    #all-in-one .img-container {
      width: 100% !important;
      margin-bottom: 20px !important;
      margin-right: 0 !important;
      float: none !important;
      display: block !important;
    }

    #all-in-one .img-container img {
      margin-left: auto;
      margin-right: auto;
    }

    #all-in-one .text-desc-container {
      text-align: center !important;
      width: 100% !important;
      float: none !important;
      display: block !important;
    }

    #shipkoo_video {
      width: 100% !important;
      height: auto !important;
    }
  }
`;

const AllInOne = () => {
  const intl = useIntl();
  const [active, setActive] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);

  const clickHandler = () => {
    setActive(!active);
    setActive2();
    setActive3();
  };

  const clickHandler2 = () => {
    setActive();
    setActive2(!active2);
    setActive3();
  };

  const clickHandler3 = () => {
    setActive();
    setActive2();
    setActive3(!active3);
  };

  return (
    <MainWrapper>
        <div className="section" id="all-in-one-section">
          <img src={AllInOneBg} alt="shipkoo all in one" className="background"/>
          <div className="row" id="all-in-one-line"></div>
          <div className="row" id="all-in-one">
            <div id="aio-digital-platform">
              <div className="column">
                <div className={(active ? 'aio-contents active' : 'aio-contents')}>
                  <h3 className="aio-subheader">{intl.formatMessage({ id: "home-aio-title" })}</h3>
                  <p className="aio-desc">
                    {intl.formatMessage({ id: "home-aio-desc-shipping" })}
                  </p>
                </div>
                <div className={(active2 ? 'aio-contents active' : 'aio-contents')}>
                  <h3 className="aio-subheader">{intl.formatMessage({ id: "home-aio-title" })}</h3>
                  <p className="aio-desc">
                    {intl.formatMessage({ id: "home-aio-desc-inventory" })}
                  </p>
                </div>
                <div className={(active3 ? 'aio-contents active' : 'aio-contents')}>
                  <h3 className="aio-subheader">{intl.formatMessage({ id: "home-aio-title" })}</h3>
                  <p className="aio-desc">
                    {intl.formatMessage({ id: "home-aio-desc-order" })}
                  </p>
                </div>
                <div className={(active ? 'aio-btn-container active' : 'aio-btn-container')}>
                  <Link to="resources/all-in-one-digital-platform/">
                    <button className={(active ? 'active' : '')} onMouseEnter={clickHandler}>
                      <span className="btn-strong">{intl.formatMessage({ id: "home-aio-btn-inventory" })}</span>
                    </button>
                  </Link>
                  <Link to="resources/all-in-one-digital-platform/">
                    <button className={(active2 ? 'active' : '')} onMouseEnter={clickHandler2}>
                      <span className="btn-strong">{intl.formatMessage({ id: "home-aio-btn-order" })}</span>
                    </button>
                  </Link>
                  <Link to="resources/all-in-one-digital-platform/">
                    <button className={(active3 ? 'active' : '')} onMouseEnter={clickHandler3}>
                      <span className="btn-strong">{intl.formatMessage({ id: "home-aio-btn-shipping" })}</span>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="column last">
              </div>
              <div className="clear"></div>
            </div>
          </div>
          <img src={InventoryManagement} className={(active ? 'aio-contents aio-image active' : 'aio-contents aio-image')} alt = "All-in-One Digital Platform" />
          <img src={OrderManagement} className={(active2 ? 'aio-contents aio-image order-management active' : 'aio-contents aio-image order-management')} alt="Order Management" />
          <img src={ShippingManagement} className={(active3 ? 'aio-contents aio-image active shipping-management' : 'aio-contents aio-image shipping-management')} alt="Shipping Management" />
        </div>
        <div className="clear"></div>
        <img src={WhiteLine} className="white-line" alt="WhiteLine" />
    </MainWrapper>
  );
};

export default AllInOne;

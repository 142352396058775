import styled from 'styled-components';

import { mediaMin, mediaMax } from 'common/styledMixins';
import { breakpoint } from 'common/styledVars';
import { ArrowIcon } from 'components/icons';
import { HeaderSmall, MediumText, BaseP } from 'components/typography';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 0px;

  @media ${mediaMin(breakpoint.desktop)} {
    margin-bottom: 60px;
    flex-direction: row;
  }

  & > div:first-of-type {
    background-image: linear-gradient(to bottom, #ffffff 2%, #faf4ff);
  }

  & > div:nth-of-type(2) {
    background-image: linear-gradient(to bottom, #ffffff 2%, #f5faff);
  }
`;

export const CardBody = styled.div`
  position: relative;
  min-height: 635px;
  padding: 55px 72px 71px;
  padding: 47px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  border: 1px solid #ecf2f1;

  & > svg {
    position: absolute;
    left: 55px;
    top: 86px;
    transform: translate(0, -50%);
  }

  & > ${HeaderSmall} {
    margin: 21px 0 40px;
    max-width: 400px;
  }

  & > ${BaseP} {
    margin-top: 128px;
  }
  @media only screen and (max-width: 981px) {
    padding: 25px;
  }
  @media only screen and (min-width: 1024px) {
    padding-bottom: 70px;
  }
`;

export const CircleBox = styled.div`
  span {
    display: none;
  }
`;

export const AtrributeCard = styled.div`
  padding: 33px 0 24px;
  display: flex;
  flex-direction: column;
  height: 230px;
  border-radius: 17px;
  box-shadow: 0 23px 100px 0 rgba(230, 237, 239, 0.6);
  background-color: #ffffff;
  align-items: center;
  justify-content: flex-end;

  ${MediumText} {
    margin: 0 0 13px;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    max-width: 200px;
  }
`;

export const AttributesBox = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 20px;
  width: 100%;

  @media ${mediaMin(breakpoint.desktop)} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${mediaMax(breakpoint.desktop)} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${mediaMax(breakpoint.tablet)} {
    grid-template-columns: repeat(1, 1fr);
  }

`;

export const Value = styled.div`
  position: relative;
  opacity: 0.9;
  font-size: 50px;
  font-weight: 500;
`;

export const ValueUnit = styled.div`
  position: absolute;
  top: 22px;
  right: -20px;
  opacity: 0.3;
  font-size: 12px;
`;

export const Arrow = styled(ArrowIcon)`
  position: absolute;
  left: -23px;
  top: 20px;
`;

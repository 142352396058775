import React from 'react';
import { CircleProgress } from 'react-gradient-progress';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { color } from 'common/styledVars';
import { DropLogo, ZulilyLogo } from 'components/icons';
import { MediumText } from 'components/typography';
import { WidthWrapper } from 'components/wrappers';
import { mediaMin } from 'common/styledMixins';
import { breakpoint } from 'common/styledVars';
import styled from 'styled-components';
import {
  Content,
  AttributesBox,
  CircleBox,
  Arrow,
  AtrributeCard,
  Value,
  CardBody,
  ValueUnit,
} from './styled';

const BaseTransparentP = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: #9db1ad;
  line-height: 35px;

  @media only screen and (min-width: 1024px) {
    font-size: 16px;
  }

`;

const BaseP = styled.span`
  margin-top: 65px;
  font-weight: 300;
  font-size: 14px;
  font-weight: 300;
  color: #00AF85;
  display: block;

  @media only screen and (min-width: 1024px) {
    font-size: 16px;

  }

  @media only screen and (max-width: 1024px) {
    margin-top: 20px;
  }

`;

const HeaderSmall = styled.h4`
  max-width: 400px;
  margin: 21px 0px 40px;
  opacity: 0.9;
  font-size: 21px;
  font-weight: 500;

  @media ${mediaMin(breakpoint.desktop)} {
    font-size: 26px;
  }
`;

const Partners = () => {
  const intl = useIntl()
  return (
    <WidthWrapper size={1440}>
      <Content>
        <CardBody>
          <Link to={'/case-studies/zulily'}>
            <ZulilyLogo />
          </Link>
          <div className="partners-number">
            <BaseP style={{ color: '#7f1aff' }}>01</BaseP>
          </div>
          <HeaderSmall>
            <Link to={'/case-studies/zulily'}>
              {intl.formatMessage({ id: "home-zulily-header" })}
            </Link>
          </HeaderSmall>
          <AttributesBox>
            <AtrributeCard>
              <CircleBox>
                <CircleProgress
                  percentage={50}
                  width={47}
                  secondaryColor="#EAF1EF"
                  primaryColor={['#8bdfbd', '#00b399']}
                />
              </CircleBox>
              <Value>
                50
                <Arrow />
                <ValueUnit>%</ValueUnit>
              </Value>
              <MediumText>{intl.formatMessage({ id: "home-zulily-warehousing" })}</MediumText>
              <BaseTransparentP>{intl.formatMessage({ id: "home-cost" })}</BaseTransparentP>
            </AtrributeCard>

            <AtrributeCard>
              <CircleBox>
                <CircleProgress
                  percentage={40}
                  width={47}
                  secondaryColor="#EAF1EF"
                  primaryColor={['#d7bfff', '#ae8bff']}
                />
              </CircleBox>
              <Value>
                40
                <Arrow />
                <ValueUnit>%</ValueUnit>
              </Value>
              <MediumText>{intl.formatMessage({ id: "home-zulily-operating" })}</MediumText>
              <BaseTransparentP>{intl.formatMessage({ id: "home-cost" })}</BaseTransparentP>
            </AtrributeCard>

            <AtrributeCard>
              <CircleBox>
                <CircleProgress
                  percentage={20}
                  width={47}
                  secondaryColor="#EAF1EF"
                  primaryColor={['#59daff', '#00c6ff']}
                />
              </CircleBox>
              <Value>
                20
                <Arrow />
                <ValueUnit>%</ValueUnit>
              </Value>
              <MediumText>{intl.formatMessage({ id: "home-zulily-shipping" })}</MediumText>
              <BaseTransparentP>{intl.formatMessage({ id: "home-cost" })}</BaseTransparentP>
            </AtrributeCard>
          </AttributesBox>
        </CardBody>
        <CardBody>
          <Link to={'/case-studies/drop'}>
            <DropLogo />
          </Link>
          <div className="partners-number">
            <BaseP style={{ color: color.green.persian }}>02</BaseP>
          </div>
          <HeaderSmall>
            <Link to={'/case-studies/drop'}>
              {intl.formatMessage({ id: "home-drop-header" })}
            </Link>
          </HeaderSmall>
          <AttributesBox>
            <AtrributeCard>
              <CircleBox>
                <CircleProgress
                  percentage={60}
                  width={47}
                  secondaryColor="#EAF1EF"
                  primaryColor={['#8bdfbd', '#00b399']}
                />
              </CircleBox>
              <Value>
                60
                <Arrow />
                <ValueUnit>%</ValueUnit>
              </Value>
              <MediumText>{intl.formatMessage({ id: "home-drop-warehousing" })}</MediumText>
              <BaseTransparentP>{intl.formatMessage({ id: "home-cost" })}</BaseTransparentP>
            </AtrributeCard>

            <AtrributeCard>
              <CircleBox>
                <CircleProgress
                  percentage={70}
                  width={47}
                  secondaryColor="#EAF1EF"
                  primaryColor={['#d7bfff', '#ae8bff']}
                />
              </CircleBox>
              <Value>
                70
                <Arrow />
                <ValueUnit>%</ValueUnit>
              </Value>
              <MediumText>{intl.formatMessage({ id: "home-drop-operating" })}</MediumText>
              <BaseTransparentP>{intl.formatMessage({ id: "home-cost" })}</BaseTransparentP>
            </AtrributeCard>

            <AtrributeCard>
              <CircleBox>
                <CircleProgress
                  percentage={5}
                  width={47}
                  secondaryColor="#EAF1EF"
                  primaryColor={['#59daff', '#00c6ff']}
                />
              </CircleBox>
              <Value>
                5
                <Arrow />
                <ValueUnit>%</ValueUnit>
              </Value>
              <MediumText>{intl.formatMessage({ id: "home-drop-sales-taxes" })}</MediumText>
              <BaseTransparentP>{intl.formatMessage({ id: "home-cost" })}</BaseTransparentP>
            </AtrributeCard>
          </AttributesBox>
        </CardBody>
      </Content>
    </WidthWrapper>
  );
};

export default Partners;
